<template>
  <div class="footer">
    <div class="f-container">
      <div class="row">
        <div class="col">
          <img width="96" src="@/assets/img/logo1.png" alt="" />
          <div class="desc">
            Transforming interaction with AI avatars. <br>
            Experience future communication with intelligent agents.
          </div>
        </div>
        <div class="col">
          <div class="box" v-if="route.path === '/urltovideo'">
            <div class="title">Products</div>
            <div class="label" v-for="item in products" :key="item.label">
              <a :href="item.url" target="_blank">{{ item.label }}</a>
            </div>
          </div>
          <div class="box" v-else>
            <div class="title">Products</div>
            <div class="label" v-for="item in product" :key="item.label">
              <a :href="item.url" target="_blank">{{ item.label }}</a>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="box">
            <div class="title">Contact Us</div>
            <div class="label">
              <img width="24" src="@/assets/img/address.png" alt="" />
              Singapore,6 RAFFLES QUAY #14-06
            </div>
            <div class="label">
              <img width="24" src="@/assets/img/email.png" alt="" />
              sg@laipic.us
            </div>
            <div class="label">
              <a href="https://www.youtube.com/@Panda72.AI_official" target="_blank">
                <img width="24" src="@/assets/img/youtube.png" alt="" />
                Youtube
              </a>
            </div>
            <div class="label">
              <a href="https://www.facebook.com/profile.php?id=61550910375870" target="_blank"><img width="24"
                  src="@/assets/img/facebook.png" alt="" />
                Facebook</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { reactive, watch } from "vue";
import { useRoute } from "vue-router";

// const aboutArr = reactive([
//   {
//     label: "Our Partners",
//     url: "#partners",
//   },
//   {
//     label: "Our Ai Products Matrix",
//     url: "#products",
//   },
//   {
//     label: "Proven Results",
//     url: "#results",
//   },
//   {
//     label: "Honor Of Laipic",
//     url: "#honor",
//   },
//   {
//     label: "About Laipic",
//     url: "#about",
//   },
//   {
//     label: "Our Mission",
//     url: "#mission",
//   },
//   {
//     label: "History Of Laipic",
//     url: "#history",
//   },
// ]);
const urltoads = import.meta.env.VITE_URLTOADS
const sdkurl = import.meta.env.VITE_CHATSDK
const products = reactive([
  {
    label: "LAiPIC",
    url: "/",
  },
  {
    label: "Doratoon",
    url: "https://www.doratoon.com/",
  },
  {
    label: "AI Avatar",
    url: urltoads,
  },
  {
    label: "Visual AI Agent",
    url: sdkurl,
  },
  {
    label: "InnAIO AI Store",
    url: "https://aistore.panda72.ai",
  },
]);
const product = reactive([
  {
    label: "AI Video Ads",
    url: "/urltovideo",
  },
  {
    label: "Doratoon",
    url: "https://www.doratoon.com/",
  },
  {
    label: "AI Avatar",
    url: urltoads,
  },
  {
    label: "Visual AI Agent",
    url: sdkurl,
  },
  {
    label: "InnAIO AI Store",
    url: "https://aistore.panda72.ai",
  },
]);

const route = useRoute();
watch(route, () => {
  scrollToHash();
});

function scrollTo(item: any) {
  const el = document.querySelector(`${item.url}`) as HTMLElement;
  console.log(el.offsetTop);
  window.scrollTo({
    top: el.offsetTop,
    behavior: "smooth",
  });
}
function scrollToHash() {
  setTimeout(() => {
    if (route.hash) {
      scrollTo({ url: route.hash });
    }
  }, 10);
}
</script>
<style lang="scss" scoped>
.footer {
  width: 100%;
  height: 420px;
  background: #fff;
  padding-top: 75px;
  // padding-bottom: 71px;
  border-top: 1px solid rgba(0, 0, 0, 0.05);
  box-sizing: border-box;
  font-family: Inter-Medium;
  color: #333;

  .f-container {
    width: 1034px;
    margin: 0 auto;
    position: relative;
  }

  .logo {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 101px;
    height: 39px;
    background: #ffffff;
    border-radius: 1px 1px 1px 1px;
  }

  .row {
    display: flex;
    justify-content: flex-start;
  }

  .col {
    width: 45%;
    display: flex;
    justify-content: flex-end;

    &:nth-child(1) {
      display: block;
      width: 30%;
    }

    &:nth-child(2) {
      width: 25%;
    }
  }

  .col {
    font-size: 14px;
    font-weight: 600;
    color: #333;
    text-align: left;

    // .box {
    //   width: 180px;
    // }
    .desc {
      width: 320px;
      height: 72px;
      font-family: Inter-Medium, Inter;
      font-weight: 500;
      font-size: 14px;
      color: #333333;
      line-height: 24px;
      text-align: left;
      font-style: normal;
      text-transform: none;
      margin-top: 48px;
    }

    .title {
      font-family: Inter-Semi Bold, Inter;
      font-weight: normal;
      font-size: 16px;
      color: #333333;
      line-height: 16px;
      text-align: left;
      font-style: normal;
      text-transform: none;
      margin-bottom: 48px;
    }

    .label {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 27px;
      height: 24px;

      img {
        margin-right: 10px;
      }

      div {
        cursor: pointer;
      }
    }

    a {
      font-family: Inter-Medium, Inter;
      font-weight: 500;
      font-size: 14px;
      color: #333;
      line-height: 14px;
      text-align: left;
      font-style: normal;
      text-transform: none;
      text-decoration: unset;
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }

    a:hover {
      color: #666;
      //   text-decoration: underline;
    }

    .text {
      font-size: 14px;
      color: rgba(255, 255, 255, 0.8);
      line-height: 16px;
      font-weight: 500;
    }

    .icon-box {
      margin-top: 16px;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      img {
        margin-right: 20px;
      }
    }
  }
}
</style>
