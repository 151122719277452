<template>
  <div class="header">
    <img class="logo" @click="router.push('/')" width="119" src="@/assets/img/logo.png" alt="" />
    <div class="v-container">
      <div class="header-box">
        <div class="nav-menu">
          <div class="nav-item">
            <el-dropdown placement="top" :teleported="false" @visible-change="visibleChange" :class="{ active: show }">
              <span class="el-dropdown-link">
                Products
                <el-icon>
                  <ArrowDownBold />
                </el-icon>
              </span>
              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item v-for="item in products" :key="item.name">
                    <a :href="item.url" target="_blank">{{ item.name }}</a>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </div>
          <div class="nav-item" @click="showMeiqia">Contact Us</div>
          <div class="nav-item" @click="openWin(`${urltoads}/pricing`)">Pricing</div>
        </div>
      </div>
    </div>
    <!-- <div class="contact-btn" @click="openWin('https://www.panda72.ai/video/login')">
      Get Started
    </div> -->
    <div class="userhead" v-show="loginStore.loginStatus">
      <UserHead></UserHead>
    </div>
  </div>
</template>
<script setup lang="ts">
import UserHead from "@/components/header/userHead.vue";
import { ArrowDownBold } from "@element-plus/icons-vue";
import { useLoginStore } from "@/store";
import axios from "axios";
import { openWin } from "@/utils";
import { useRouter } from "vue-router";
const router = useRouter();
const show = ref(false);
const urltoads = import.meta.env.VITE_URLTOADS
const sdkurl = import.meta.env.VITE_CHATSDK
const products = ref([
  {
    name: "LAiPIC",
    url: "/",
  },
  {
    name: "Doratoon",
    url: "https://www.doratoon.com/",
  },
  {
    name: "AI Avatar",
    url: urltoads,
  },
  {
    name: "Visual AI Agent",
    url: sdkurl,
  },
  {
    name: "InnAIO AI Store",
    url: "https://aistore.panda72.ai",
  },
]);
function visibleChange(val: boolean) {
  show.value = val;
}

// 美洽客服
function showMeiqia() {
  if (!window) return;
  function allSetFn() {
    // console.log("美洽网站插件初始化完毕。");
    // console.log(location.hostname);
  }
  if (window && !window._MEIQIA) {
    (function (a: any, b: any, c: any, d: any, j?: any, s?: any) {
      a[d] =
        a[d] ||
        function () {
          (a[d].a = a[d].a || []).push(arguments);
        };
      (j = b.createElement(c)), (s = b.getElementsByTagName(c)[0]);
      j!.async = true;
      j.charset = "UTF-8";
      j.src = "https://static.meiqia.com/widget/loader.js";
      s.parentNode.insertBefore(j, s);
    })(window, document, "script", "_MEIQIA");
    window._MEIQIA("entId", "b05d5bfe5d2856b1bf4ff58ce9e926b0");
    window._MEIQIA("language", "en");
    window._MEIQIA("manualInit");
    window._MEIQIA("allSet", allSetFn);
    window._MEIQIA("init");
  }
  window._MEIQIA("showPanel");
}

const loginStore = useLoginStore();
const fetchUserInfo = async () => {
  const { data } = await axios.get("/media-api/session");
  if (data.code === 200) {
    loginStore.changeLogin(data.data);
  } else {
    loginStore.changeLogin({});
  }
};
fetchUserInfo()
// showMeiqia()
</script>
<style lang="scss" scoped>
.header {
  height: 80px;
  position: relative;
  background: #fff;

  .userhead {
    position: absolute;
    top: 15px;
    right: 40px;
    z-index: 9;
  }

  .header-box {
    height: 80px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-top: 15px;
  }

  .logo {
    position: absolute;
    top: 40px;
    left: 106px;
    z-index: 1;
    cursor: pointer;
  }

  .nav-menu {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 420px;
    height: 50px;
    background: #ffffff;
    box-shadow: 0px 8px 34px 0px rgba(109, 117, 185, 0.14);
    border-radius: 70px 70px 70px 70px;
    margin: 0 auto;

    .nav-item {
      padding: 0 24px;
      height: 50px;
      background: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 500;
      font-size: 18px;
      color: #333333;
      line-height: 18px;
      border-radius: 70px 70px 70px 70px;
      font-family: Inter-Medium, Inter;
      cursor: pointer;

      &:hover {
        box-shadow: 0px 8px 34px 0px rgba(109, 117, 185, 0.2);
      }

      :deep(.el-dropdown) {
        font-size: 18px;
        font-family: Inter-Medium, Inter;
        font-weight: 500;
        color: #000;
        line-height: 23px;
        text-decoration: unset;
        cursor: pointer;

        a {
          font-size: 14px;
          font-family: Inter-Regular, Inter;
          font-weight: 400;
          color: #333;
          line-height: 36px;
          text-decoration: unset;
          width: 100%;
          display: inline-block;
          padding-left: 27px;
          box-sizing: border-box;

          &:hover {
            // transform: translateX(5px);
            background: rgb(121 116 249 / 10%);
            border-radius: 8px 8px 8px 8px;
          }
        }

        .el-popper {
          width: 248px;
          //   height: 106px;
          background: #ffffff;
          border-radius: 10px;
          padding: 11px 17px;
          box-sizing: border-box;
          text-align: left;
          box-shadow: 0px 0px 24px 2px rgba(35, 33, 125, 0.1);
          margin-top: 17px;
        }

        .el-dropdown-menu__item {
          padding: 0;
          display: block;
          margin-bottom: 10px;

          //   padding-left: 27px;
          //   line-height: 32px;
          &:last-child {
            margin-bottom: 0;
          }

          //   &:hover {
          //     background: rgb(121 116 249 / 10%);
          //     border-radius: 8px 8px 8px 8px;
          //   }
        }

        .el-dropdown-menu__item:not(.is-disabled):focus {
          background: #fff;
        }

        .el-dropdown-menu {
          padding: 0;
        }

        .el-popper__arrow {
          display: none;
        }

        .el-dropdown-link {
          &:focus-visible {
            outline: unset;
          }
        }

        .el-icon {
          font-size: 14px;
          transition: all 0.1s;
        }

        &.active {
          .el-icon {
            transform: rotate(-180deg);
          }
        }

        &:hover {
          //   color: #7c63fa !important;
        }
      }
    }
  }

  .contact-btn {
    width: 157px;
    height: 50px;
    background: #f60100;
    border-radius: 10px 10px 10px 10px;
    font-family: Inter-Semi Bold, Inter;
    font-weight: normal;
    font-size: 18px;
    color: #ffffff;
    line-height: 18px;
    text-align: left;
    font-style: normal;
    text-transform: none;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 24px;
    right: 87px;
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }
  }
}
</style>
